<template>
  <secondary-page-layout :title="$t('notifications')" :displayedNavigation="true">
    <div class="px-3" ref="container">
      <div class="position-relative d-flex mt-3" v-for="notification in reversedNotifications" :key="notification.id">
        <div class="icon-box me-2">
          <icon icon-name="heart-icon"></icon>
        </div>
        <div class="w-100">
          <div class="notification-date">
            {{ getCreatedDate(notification.created_at) }}
          </div>
          <div class="notification-item">
            <div class="head">
              <h3 class="title">{{ notification && notification.data && notification.data.title }}</h3>
              <span class="time">{{ getCreatedTime(notification.created_at) }}</span>
            </div>
            <div class="body">
              <p class="text">{{ notification && notification.data && notification.data.preview }}</p>
              <img :src="notification.data.image" alt="" class="notification-image">
              <a v-if="notification && notification.data && notification.data.body" class="btn" :href="notification.data.body">Подробнее</a>
            </div>
          </div>
        </div>
      </div>
      <div ref="anchor"></div>
    </div>
  </secondary-page-layout>
</template>

<script>
import NotificationModal from "../components/Modals/NotificationModal";
import moment from "moment";

export default {
  name: "Notifications",
  data() {
    return {
      notifications: [],
    }
  },
  async mounted() {
    await this.getNotifications();
    this.scrollToElement();
  },
  watch: {
    notifications() {
      this.$nextTick(()=>this.scrollToElement());
    }
  },
  computed:{
    reversedNotifications() {
      const { notifications } = this;
      return notifications.reverse();
    }
  },
  methods: {
    async getNotifications() {
      const { data } = await window.axios.get('/notifications');

      this.notifications = data;
    },
    readNotification(notification) {
      this.$modal.show(NotificationModal, {item: notification}, this.$modalConfig,);
      window.axios.get(`/notifications/${notification.id}`);
    },
    getCreatedTime(date) {
      return moment(date).format("HH:mm")
    },
    getCreatedDate(date) {
      let yesterday = moment(moment(new Date()).format("YYYY-MM-DD")).subtract(1, "days").format("YYYY-MM-DD");
      let formattedDate = moment(date).format("YYYY-MM-DD");
      if(moment(formattedDate).isSame(moment(new Date()).format("YYYY-MM-DD"), 'day')) {
        return this.$i18n.t('today');
      }
      else if(moment(formattedDate).isSame(yesterday, 'day')) {
        return this.$i18n.t('yesterday');
      }
      else {
        return moment(date).format("DD.MM.YYYY")
      }
    },
    scrollToElement() {
      const el = this.$refs.anchor;

      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.notification-item {
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 12px 16px rgba(0, 0, 0, 0.05);
  border-radius: 0 12px 12px 12px;
  width: 100%;
  padding: 12px 16px;
  margin-bottom: 16px;
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    border-bottom: 1px solid #C8C7CC;
    margin-bottom: 8px;
    .title {
      margin-bottom: 0;
      font-weight: bold;
      font-size: 14px;
    }
    .time {
      font-size: 11px;
      text-align: right;
      letter-spacing: 0.07px;
      color: #99A3B3;
    }
  }
  .body {
    .text {
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 8px;
    }
    .btn {
      font-weight: bold;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #2AA65C;
      padding: 14px 16px;
      width: 100%;
      border-radius: 12px;
      background: #EAF6EF;
    }
  }
}
.notification-date {
  position: absolute;
  top: -25px;
  color: #99A3B3;
  font-size: 11px;
  left: 50%;
  transform: translateX(-50%);
}

.notification-image {
  height: auto;
  max-height: 400px;
  width: 100%;
  object-fit: cover;
  margin-bottom: 12px;
}

//@media (max-width: 475px) {
//  .notification-image {
//    height: 200px;
//  }
//}
</style>
