<template>
  <modal-layout @close="$emit('close')" :title="$t('notifications')">
    <img v-if="notificationData && notificationData.image" :src="notificationData.image" alt="" class="notification-img">

    <div v-if="notificationData" v-html="notificationData.body" class="p-3 text-section injected-html" style="text-align: justify"></div>
  </modal-layout>
</template>

<script>
export default {
  name: "NotificationModal",
  props: ['item'],
  computed: {
    notificationData() {
      const { item } = this

      if(! item ) { return }

      const { data } = item

      return data;
    }
  }
}
</script>

<style scoped>
.notification-img {
  width: 100%;
}
</style>
